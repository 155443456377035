<template>
  <div :class="$style.home">
    <div :class="$style.header">
      <div :class="$style.left">
        <a-tooltip placement="right">
          <template slot="title"> 退出预览 </template>
          <i class="ri-logout-box-line" @click="back()" />
        </a-tooltip>
      </div>
      <div :class="$style.right">
        <a-tooltip placement="bottom">
          <template slot="title"> 手机 </template>
          <span :class="$style.icon">
            <i class="ri-smartphone-line" />
          </span>
        </a-tooltip>
        <a-tooltip placement="bottom">
          <template slot="title"> pc </template>
          <span :class="[$style.icon, $style.disable]">
            <i class="ri-computer-line" />
          </span>
        </a-tooltip>
      </div>
      <div>
        {{ title }}
      </div>
    </div>
    <div :class="$style.content">
      <div :class="$style.phone">
        <div :class="$style.title">{{ childTitle }}</div>
        <iframe v-if="show" id="iframe" ref="iframe" :src="page" frameborder="0" />
        <div :class="$style.btns">
          <a-tooltip placement="right">
            <template slot="title">
              <span>点击后退</span>
            </template>
            <div>
              <i class="ri-arrow-left-line" @click="onBack" />
            </div>
          </a-tooltip>

          <a-tooltip placement="right">
            <template slot="title">
              <span>点击前进</span>
            </template>
            <div>
              <i class="ri-arrow-right-line" @click="onForward" />
            </div>
          </a-tooltip>

          <a-tooltip placement="right">
            <template slot="title">
              <span>打开首页</span>
            </template>
            <div>
              <i class="ri-home-6-line" @click="onHome" />
            </div>
          </a-tooltip>
        </div>
      </div>
    </div>
    <div :class="$style.preview">
      <a-popover v-if="data.qr_code && data.qr_code.general" placement="left" title="移动网页端">
        <template slot="content">
          <div :class="$style.code">
            <img :src="data.qr_code.general" />
            <div :class="$style.text">
              <i class="ri-qr-scan-2-line" />
              请使用手机扫码预览
            </div>
            <BjButton slot="suffix" :class="$style.copy" type="primary" @click="onCopyLink('general')">
              <i class="ri-file-copy-line left" />
              复制链接
            </BjButton>
          </div>
        </template>
        <img :src="oss + '/manage/icon/front/h5.png'" />
      </a-popover>
      <!-- <div :class="$style.line" /> -->
      <a-popover v-if="data.qr_code && data.qr_code.wx" placement="left" title="微信小程序">
        <template slot="content">
          <div :class="$style.code">
            <img :src="data.qr_code.wx" />
            <div :class="$style.text">
              <i class="ri-qr-scan-2-line" />
              请使用微信APP扫码预览
            </div>
            <BjButton slot="suffix" :class="$style.copy" type="primary" @click="onCopyLink('wx')">
              <i class="ri-download-line left" />
              下载小程序码
            </BjButton>
          </div>
        </template>
        <img :src="oss + '/manage/icon/front/miniprogram.png'" />
      </a-popover>

      <a-popover v-if="data.qr_code && data.qr_code.baidu" placement="left" title="百度小程序">
        <template slot="content">
          <div :class="$style.code">
            <img :src="data.qr_code.baidu" />
            <div :class="$style.text">
              <i class="ri-qr-scan-2-line" />
              请使用手机扫码预览
            </div>
            <BjButton slot="suffix" :class="$style.copy" type="primary" @click="onCopyLink('baidu')">
              <i class="ri-file-copy-line" />
              复制链接
            </BjButton>
          </div>
        </template>
        <img :src="oss + '/manage/icon/front/baidu.png'" />
      </a-popover>

      <a-popover v-if="data.qr_code && data.qr_code.baidu" placement="left" title="字节小程序">
        <template slot="content">
          <div :class="$style.code">
            <img :src="data.qr_code.baidu" />
            <div :class="$style.text">
              <i class="ri-qr-scan-2-line" />
              请使用手机扫码预览
            </div>
            <BjButton slot="suffix" :class="$style.copy" type="primary" @click="onCopyLink('baidu')">
              <i class="ri-file-copy-line" />
              复制链接
            </BjButton>
          </div>
        </template>
        <img :src="oss + '/manage/icon/front/bytedance.png'" />
      </a-popover>
      <!-- <div :class="$style.line" /> -->
      <a-popover v-if="data.qr_code && data.qr_code.ios" placement="left" title="ios">
        <template slot="content">
          <div :class="$style.code">
            <img :src="data.qr_code.ios" />
            <div :class="$style.text">
              <i class="ri-qr-scan-2-line" />
              请使用手机扫码预览
            </div>
            <BjButton slot="suffix" :class="$style.copy" type="primary" @click="onCopyLink('ios')">
              <i class="ri-file-copy-line" />
              复制链接
            </BjButton>
          </div>
        </template>
        <img :src="oss + '/manage/icon/front/ios.png'" />
      </a-popover>

      <a-popover v-if="data.qr_code && data.qr_code.android" placement="left" title="安卓">
        <template slot="content">
          <div :class="$style.code">
            <img :src="data.qr_code.android" />
            <div :class="$style.text">
              <i class="ri-qr-scan-2-line" />
              请使用手机扫码预览
            </div>
            <BjButton slot="suffix" :class="$style.copy" type="primary" @click="onCopyLink('android')">
              <i class="ri-file-copy-line" />
              复制链接
            </BjButton>
          </div>
        </template>
        <img :src="oss + '/manage/icon/front/android.png'" />
      </a-popover>
    </div>
  </div>
</template>

<script>
import { previewService } from '@/service'
import { BASE_URL } from '@/utils/variable'

const service = new previewService()

export default {
  name: 'Home',
  data() {
    return {
      title: '文化云预览',
      page: null,
      type: null,
      id: null,
      data: {},
      show: true,
    }
  },
  computed: {
    childTitle() {
      return this.$store.state.user.cultural_cloud_name
    },
  },
  created() {
    this.type = this.$route.query.type
    this.id = this.$route.query.id
    this.getInfo()
  },
  methods: {
    async getInfo() {
      try {
        const { data } = await service.getInfo({
          type: this.type,
          id: this.id,
        })
        this.data = data
        this.page = data.link_url
      } catch (e) {
        this.$router.go(-1)
      }
    },
    onForward() {
      // this.$router.go(1)
      window.history.go(1)
    },
    onBack() {
      // this.$router.go(-1)
      window.history.go(-1)
    },
    onHome() {
      this.page = this.data.home_url
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    back() {
      if (this.$store.state.editorBackName) {
        this.$router.push({
          path: this.$store.state.editorBackName,
        })
      } else {
        this.$router.push({
          name: 'dashboard',
        })
      }
    },
    onCopyLink(type) {
      if (type === 'wx') {
        window.open(BASE_URL + '/api/common/util/file/download?path=' + this.data.qr_code.wx)
      }
      if (type === 'general') {
        var Url2 = this.data.link_url
        var oInput = document.createElement('input')
        oInput.value = Url2
        document.body.appendChild(oInput)
        oInput.select() // 选择对象
        document.execCommand('Copy') // 执行浏览器复制命令
        this.$message.success('复制成功')
        oInput.parentNode.removeChild(oInput) // 删除
      }
    },
  },
}
</script>

<style lang="less" module>
.home {
  min-width: 1000px;

  .header {
    position: relative;
    width: 100%;
    height: 60px;
    line-height: 60px;
    color: #000;
    font-size: 16px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
    padding: 0 20px;

    .left {
      position: absolute;
      top: 0;
      left: 20px;
      display: flex;
      align-items: center;
      width: 100px;
      height: 60px;
      font-size: 20px;

      i:hover {
        color: @primary-color;
      }
    }

    .right {
      position: absolute;
      top: 0;
      right: 20px;
      display: flex;
      align-items: center;
      width: 96px;
      height: 60px;
      font-size: 20px;

      .icon {
        width: 48px;
      }

      .icon:hover {
        background-color: #fafafa;
        cursor: pointer;
      }

      .disable {
        color: #eee;
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .phone {
      position: relative;
      margin-top: 30px;
      width: 438px;
      height: 884px;
      background-image: url('https://cultural-cloud-static.oss-cn-hangzhou.aliyuncs.com/manage/media/assets/preview_iPhone.png');
      background-size: cover;
      text-align: center;
      padding-top: 100px;

      iframe {
        width: 390px;
        height: 765px;
        border-radius: 0 0 50px 50px;
      }

      .title {
        position: absolute;
        width: 390px;
        height: 50px;
        top: 50px;
        left: 24px;
        line-height: 50px;
        font-size: 16px;
        text-align: center;
      }
    }
  }

  .btns {
    position: absolute;
    top: 100px;
    right: -70px;
    width: 40px;
    height: 120px;
    border: 1px solid #eee;

    & > div {
      height: 40px;
      border-bottom: 1px solid #eee;
      text-align: center;
      line-height: 40px;
      font-size: 18px;
      color: #000;
    }

    & > div:hover {
      background-color: #fafafa;
      cursor: pointer;
    }
  }

  .preview {
    right: 30px;
    width: 40px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      margin-bottom: 20px;
    }

    .line {
      width: 40px;
      height: 1px;
      border-top: 1px solid #eee;
      margin-bottom: 20px;
    }
  }
}

.code {
  text-align: center;
  width: 230px;

  img {
    width: 140px;
    height: 140px;
    object-fit: cover;
  }

  .text {
    margin-bottom: 14px;
    margin-top: 10px;
    font-size: 12px;

    i {
      color: #000;
      font-size: 18px;
      vertical-align: -4px;
      margin-right: 10px;
    }
  }

  .copy {
    width: 140px;
  }
}
</style>
